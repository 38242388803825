import type { ReactNode } from "react";
import React, { useCallback, useEffect, useState } from "react";
import type { Swiper as SwiperType } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { CaretLeftIcon, CaretRightIcon } from "@radix-ui/react-icons";

import { cn } from "~/lib/ui";

import { Button } from "./button";

export function CarouselControls({
  swiperInstance,
  isLooped,
  hideControlsOnMobile,
  isHeroBanner,
}: {
  swiperInstance: SwiperType | null;
  isLooped?: boolean;
  hideControlsOnMobile?: boolean;
  isHeroBanner?: boolean;
}) {
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  const handleReachBeginning = useCallback(() => setIsBeginning(true), []);
  const handleReachEnd = useCallback(() => setIsEnd(true), []);
  const handleFromEdge = useCallback(() => {
    setIsBeginning(false);
    setIsEnd(false);
  }, []);

  useEffect(() => {
    if (!swiperInstance) return;

    setIsBeginning(swiperInstance.isBeginning);
    setIsEnd(swiperInstance.isEnd);

    swiperInstance.on("reachBeginning", handleReachBeginning);
    swiperInstance.on("reachEnd", handleReachEnd);
    swiperInstance.on("fromEdge", handleFromEdge);

    return () => {
      swiperInstance.off("reachBeginning", handleReachBeginning);
      swiperInstance.off("reachEnd", handleReachEnd);
      swiperInstance.off("fromEdge", handleFromEdge);
    };
  }, [swiperInstance]);

  const buttonClass = cn(
    "absolute top-1/2 z-10 h-10 w-10 -translate-y-1/2 transform rounded-full border-0 bg-white p-0 shadow-banner disabled:invisible",
    "opacity-50 group-hover:opacity-100 transition-opacity duration-300",
    hideControlsOnMobile && "hidden md:block",
  );

  return (
    <>
      <Button
        variant="ghost"
        className={cn(buttonClass, "left-2")}
        onClick={() => swiperInstance?.slidePrev()}
        disabled={isBeginning && !isLooped}
      >
        <CaretLeftIcon
          className="h-6 w-6 origin-center object-none"
          color="#121212"
        />
      </Button>
      <Button
        variant="ghost"
        className={cn(
          buttonClass,
          !isHeroBanner && "notHeroBanner right-[calc(-5%+.5rem)]",
          isHeroBanner && "isHeroBanner  right-2",
        )}
        onClick={() => swiperInstance?.slideNext()}
        disabled={isEnd && !isLooped}
      >
        <CaretRightIcon
          className="h-6 w-6 origin-center object-none"
          color="#121212"
        />
      </Button>
    </>
  );
}

const Carousel = React.forwardRef<
  React.ElementRef<typeof Swiper>,
  Omit<React.ComponentPropsWithoutRef<typeof Swiper>, "children"> & {
    children: ReactNode[] | ReactNode;
    slideClassName?: string;
    customNavigation?: ReactNode;
    useBubblesNavigation?: boolean;
    slideProps?: React.ComponentPropsWithoutRef<typeof SwiperSlide>;
    hideControlsOnMobile?: boolean;
    isHeroBanner?: boolean;
  }
>(
  (
    {
      className,
      slideClassName,
      children,
      customNavigation,
      useBubblesNavigation,
      slideProps = {},
      onSwiper,
      hideControlsOnMobile,
      isHeroBanner,
      ...props
    },
    ref,
  ) => {
    if (!Array.isArray(children))
      throw new Error("Expecting children to be array!");

    const [swiperInstance, setSwiperInstance] = useState<SwiperType | null>(
      null,
    );

    return (
      <Swiper
        {...props}
        className={cn("group relative !overflow-x-visible ", className)}
        onSwiper={swiper => {
          setSwiperInstance(swiper);
          onSwiper?.(swiper);
        }}
        ref={ref}
      >
        {children.length > 1 && customNavigation}
        {useBubblesNavigation && (
          <CarouselControls
            swiperInstance={swiperInstance}
            isLooped={props.loop}
            hideControlsOnMobile={hideControlsOnMobile}
            isHeroBanner={isHeroBanner}
          />
        )}
        {children.length > 1 ? (
          children?.map((child, i) => (
            <SwiperSlide key={i} className={slideClassName} {...slideProps}>
              {child}
            </SwiperSlide>
          ))
        ) : (
          <SwiperSlide className={slideClassName} {...slideProps}>
            {children}
          </SwiperSlide>
        )}
      </Swiper>
    );
  },
);
Carousel.displayName = "Carousel";

export { Carousel };
